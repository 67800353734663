/*
 * @Description: element-puls
 * @Author: yaoxiaoxiao
 * @Date: 2021-03-16 16:50:02
 * @LastEditors: rongcheng
 * @LastEditTime: 2022-03-15 18:52:06
 */
import { App } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/lib/theme-chalk/index.css'
import 'element-plus/lib/theme-chalk/el-icon.css'
import 'dayjs/locale/zh-cn'
import locale from 'element-plus/lib/locale/lang/zh-cn'

const installElement = (app: App<Element>): void => {
  app.use(ElementPlus, { locale })
}

export default installElement
