
import SubMenu from './main-siderbar-sub-menu.vue'
// import { routes } from '@/router/index'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import { computed } from 'vue'
export default {
  name: 'sub-menu',
  props: {
    menu: {
      type: Object,
      required: true
    }
  },
  components: {
    SubMenu
  },
  setup() {
    const router = useRouter()
    const store = useStore()

    const developmentMode = computed(() => store.state.common.developmentMode)
    const gotoRouteHandle = name => {
      if (name === store.state.common.siderbarMenuActiveName) return
      store.state.common.siderbarMenuActiveName = name
      // store.state.common.contentTabsActiveName = name
      // console.log(name, router, 'name')
      // console.log(routes, 'routes')
      router.push({ name })
    }
    const validCondition = (condition) => {
      /**
        *  isDev  开发环境 直接显示
        *  isPermission  后端权限
        *  isHome  首页
        */
      const isDev = (developmentMode.value && !condition.meta?.isMenuHide)
      const isPermission = !!store.state.user.userMenuMap[condition.name] && condition.meta

      const isHome = condition.name === 'Home'
      return isPermission || isHome || !isDev
    }
    const typeChildren = (menu) => {
      if ((Array.isArray(menu.children) && menu.children.length > 0)) {
        const arr = menu.children.filter(item => {
          if (!item.meta.isMenuHide) {
            return item
          }
        })
        return arr.length > 0
      } else {
        return menu.meta.isMenuHide
      }
    }

    return {
      gotoRouteHandle,
      typeChildren,
      validCondition,
      store,
      developmentMode
    }
  }
}
