
/*
 * @Description: async result tool
 * @Author: yaoxiaoxiao
 * @Date: 2021-03-30 17:41:36
 * @LastEditors: rongcheng
 * @LastEditTime: 2022-04-25 18:00:22
 */
interface Result {
  code: number;
  msg: string;
}

export class ErrorResult extends Error implements Result {
  constructor(data) {
    super()
    this.code = data.code
    this.msg = data.msg
  }

  code: number
  msg: string
}
// 防止重复请求
export function firstPromise (promiseFunction) {
  let instance = null
  return function (...args) {
    instance || (instance = promiseFunction.apply(this, args)
      .finally(() => (instance = null)))
    return instance
  }
}

export const toResult = p => {
  return p
    .then((data) => {
      if (data.success && data.code === 200) return Promise.resolve([null, data.data, data.msg])
      else return Promise.reject(new ErrorResult(data))
    })
    .catch(e => Promise.resolve([e, null]))
}
