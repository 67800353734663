import { routes } from '@/router'
// routes arr =》map
const menuHandle = routes => {
  const menuMap = {}
  for (const menuItem of routes) {
    menuMap[menuItem.name] = (menuItem && menuItem.children) || [menuItem]
  }
  return menuMap
}

const mutations = {
  /**
   * 新增浏览标签
   */
  ADD_VISITED_VIEW: (state, view) => {
    if (state.visitedViews.some(v => v.path === view.path)) return
    state.visitedViews.length = 0
    state.visitedViews.push(
      Object.assign({}, view, {
        title: view.meta.title || 'no-name'
      })
    )
  },
  /**
   * 新增缓存标签
   */
  ADD_CACHED_VIEW: (state, view) => {
    if (state.cachedViews.includes(view.name)) return
    if (!view.meta.noCache) {
      state.cachedViews.push(view.name)
    }
  },
  /**
   * 删除已浏览标签
   */
  DEL_VISITED_VIEW: (state, view) => {
    for (const [i, v] of state.visitedViews.entries()) {
      if (v.path === view.path) {
        state.visitedViews.splice(i, 1)
        break
      }
    }
  },
  /**
   * 删除缓存标签
   */
  DEL_CACHED_VIEW: (state, view) => {
    const index = state.cachedViews.indexOf(view.name)
    index > -1 && state.cachedViews.splice(index, 1)
  },
  /**
   * 删除其它已浏览标签（功能未实现）
   */
  DEL_OTHERS_VISITED_VIEWS: (state, view) => {
    state.visitedViews = state.visitedViews.filter(v => {
      return v.meta.affix || v.path === view.path
    })
  },
  /**
   * 删除其它已缓存标签（功能未实现）
   */
  DEL_OTHERS_CACHED_VIEWS: (state, view) => {
    const index = state.cachedViews.indexOf(view.name)
    if (index > -1) {
      state.cachedViews = state.cachedViews.slice(index, index + 1)
    } else {
      // if index = -1, there is no cached tags
      state.cachedViews = []
    }
  },
  /**
   * 删除所有标签（功能未实现）
   */
  DEL_ALL_VISITED_VIEWS: state => {
    // keep affix tags
    const affixTags = state.visitedViews.filter(tag => tag.meta.affix)
    state.visitedViews = affixTags
  },
  /**
   * 删除所有已缓存标签（功能未实现）
   */
  DEL_ALL_CACHED_VIEWS: state => {
    state.cachedViews = []
  },
  /**
   * 更新已浏览标签（功能暂未实现）
   */
  UPDATE_VISITED_VIEW: (state, view) => {
    for (let v of state.visitedViews) {
      if (v.path === view.path) {
        v = Object.assign(v, view)
        break
      }
    }
  },
  UPDATED_MENU_MAP: (state, view) => {
    state.totalMenuList = view
    state.totalMenuMap = menuHandle(view)
  },
  /**
   * 更新活跃状态的菜单（包括顶部、侧边栏）
   */
  UPDATE_ACTIVE_MENU: (state, view) => {
    if (view.meta.isMenuHide) {
      if (view.matched.length > 1) {
        state.siderbarMenuActiveName = view.matched[view.matched.length - 2].name
      }
    } else {
      state.siderbarMenuActiveName = view.name
    }
    // state.siderbarMenuActiveName = view.name
    state.topbarMenuActiveName = view.meta.root
    state.siderbarMenuList = state.totalMenuMap[view.meta.root]
  },
  /**
   * 替换已浏览标签（页面刷新用）
   */
  REPLACE_VISITED_VIEW: (state, view) => {
    state.visitedViews = view
  },
  /**
   * 替换已缓存标签（页面刷新用）
   */
  REPLACE_CACHED_VIEW: (state, view) => {
    state.cachedViews = view
  }
}

const actions = {
  addView({ dispatch }, view) {
    dispatch('addVisitedView', view)
    dispatch('addCachedView', view)
  },
  addVisitedView({ commit }, view) {
    commit('ADD_VISITED_VIEW', view)
  },
  addCachedView({ commit }, view) {
    commit('ADD_CACHED_VIEW', view)
  },

  delView({ dispatch, state }, view) {
    return new Promise(resolve => {
      dispatch('delVisitedView', view)
      dispatch('delCachedView', view)
      resolve({
        visitedViews: [...state.visitedViews],
        cachedViews: [...state.cachedViews]
      })
    })
  },
  delVisitedView({ commit, state }, view) {
    return new Promise(resolve => {
      commit('DEL_VISITED_VIEW', view)
      resolve([...state.visitedViews])
    })
  },
  delCachedView({ commit, state }, view) {
    return new Promise(resolve => {
      commit('DEL_CACHED_VIEW', view)
      resolve([...state.cachedViews])
    })
  },

  delOthersViews({ dispatch, state }, view) {
    return new Promise(resolve => {
      dispatch('delOthersVisitedViews', view)
      dispatch('delOthersCachedViews', view)
      resolve({
        visitedViews: [...state.visitedViews],
        cachedViews: [...state.cachedViews]
      })
    })
  },
  delOthersVisitedViews({ commit, state }, view) {
    return new Promise(resolve => {
      commit('DEL_OTHERS_VISITED_VIEWS', view)
      resolve([...state.visitedViews])
    })
  },
  delOthersCachedViews({ commit, state }, view) {
    return new Promise(resolve => {
      commit('DEL_OTHERS_CACHED_VIEWS', view)
      resolve([...state.cachedViews])
    })
  },

  delAllViews({ dispatch, state }, view) {
    return new Promise(resolve => {
      dispatch('delAllVisitedViews', view)
      dispatch('delAllCachedViews', view)
      resolve({
        visitedViews: [...state.visitedViews],
        cachedViews: [...state.cachedViews]
      })
    })
  },
  delAllVisitedViews({ commit, state }) {
    return new Promise(resolve => {
      commit('DEL_ALL_VISITED_VIEWS')
      resolve([...state.visitedViews])
    })
  },
  delAllCachedViews({ commit, state }) {
    return new Promise(resolve => {
      commit('DEL_ALL_CACHED_VIEWS')
      resolve([...state.cachedViews])
    })
  },
  replaceVistedViews({ commit }, view) {
    commit('REPLACE_VISITED_VIEW', view)
  },
  replaceCachedViews({ commit }, view) {
    commit('REPLACE_CACHED_VIEW', view)
  },
  updateVisitedView({ commit }, view) {
    commit('UPDATE_VISITED_VIEW', view)
  },
  moveActiveMenu({ commit }, view) {
    commit('UPDATE_ACTIVE_MENU', view)
  }
}

export default {
  namespaced: true,
  state: {
    // 总菜单
    totalMenuList: routes,
    totalMenuMap: menuHandle(routes),
    // 侧边栏
    siderbarMenuList: [],
    // 顶部导航栏
    topbarMenuList: [],
    // 活跃侧边栏菜单
    siderbarMenuActiveName: '',
    // 活跃顶部栏菜单
    topbarMenuActiveName: '',
    // 内容，标签页（默认添加首页）
    visitedViews: [],
    // 已缓存的标签
    cachedViews: [],
    developmentMode: process.env.NODE_ENV === 'development'
  },
  mutations,
  actions
}
