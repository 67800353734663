
import service from '@/utils/request'
import fileService from '@/utils/request.file'
export const login = pramas =>
  service.request({
    method: 'post',
    url: 'admin/user/login/login',
    data: pramas
  })
export const loginOut = () =>
  service.request({
    method: 'post',
    url: 'admin/user/login/logout'
  })
export const getMessageCode = pramas =>
  service.request({
    method: 'post',
    url: 'admin/user/login/sendSms',
    data: pramas
  })
export const getResource = () =>
  service.request({
    method: 'post',
    url: 'admin/permission/getMenuPermission'
    // url: 'admin/account/limits'
  })
export const getFileUrl = pramas =>
  fileService.request({
    method: 'post',
    url: 'common/file/getFileUrl',
    data: pramas
  })
// 获取权限tree
export const getRolePermissionList = (params: any) =>
  service.request({
    method: 'post',
    url: '/admin/permission/rolePermission',
    data: params
  })

// 图形验证码
export const generateValidateCodeAPI = (params: any) =>
  service.request({
    method: 'post',
    url: 'admin/user/login/generateValidateCode',
    data: params
  })

// 账号是否已经登录
export const checkLoginStatusAPI = (params: any) =>
  service.request({
    method: 'post',
    url: 'admin/user/login/checkLoginStatus',
    data: params
  })

// 确认登录
export const confirmLoginAPI = (params: any) =>
  service.request({
    method: 'post',
    url: 'admin/user/login/confirmLogin',
    data: params
  })
