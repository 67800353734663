import config from '@/config'
import { routes, trendsRouter, isDevRouter, setRoutersAdd, resetRouter } from '@/router'

import { setToken, removeToken, setLocalUserInfo } from '@/utils/auth'
import { login, loginOut, getResource, confirmLoginAPI } from '@/api/common'
import store from '@/store/index'
interface Obj {
  [propkey: string]: any;
}
/**
 * 路由扁平化
 * @param arr
 */
const flatRoutes = (arr: any[]) => {
  return arr.reduce((int, current) => {
    if (current.parentTag === '0') {
      console.log(current, 'current----------')
    }
    int[current.name] = current
    if (current.children && Array.isArray(current.children)) {
      int = { ...int, ...((flatRoutes(current.children) as unknown) as Obj) }
    }
    return int
  }, {})
}

/**
 * @description: 返回 isMenuHide 的组件
 * @param {*} arr router
 * @return {*}
 */
const isMenuHideChild = (arr) => {
  if (!Array.isArray(arr)) return []
  return arr.filter(element => {
    if (Array.isArray(element.children) && element.children.length > 0) {
      element.children = isMenuHideChild(element.children)
    }
    if (element.meta.isMenuHide) {
      return element
    }
  })
}
// 取出带 isMenuHide 对象
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const handleMenuData = (list) => {
  const obj = list.reduce((int, current) => {
    int = [...int, ...isMenuHideChild(current)]
    return int
  }, [])
  console.log(obj, '1')
  return flatRoutes(obj)
}

/**
 *  重组router
 * @param router
 */
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const resetRouters = (routes) => {
  // console.log(JSON.stringify(routes), 'routes')
  const obj: any[] = []
  const parent = []
  Object.keys(routes).forEach((keys) => {
    // && routes[keys].children === undefined
    if (routes[keys].parentName !== '0') {
      obj.push(routes[keys])
    } else {
      parent.push(routes[keys])
    }
  })
  console.log(parent, obj, 'obj------')
  // return contrastRoutes(parent, obj)
}
/**
 * @description:  重构后台返回的数据
 * @param {*} list
 * @return {*}
 */
const handleResetRouter = (data, menuMap) => {
  let root = '' // 第一层root节点
  const routesMap = flatRoutes(trendsRouter)
  const handleRecursion = (list, _root?) => {
    const newList = list.filter((value) => {
      // 第一层节点
      if (value.parentTag === '0') {
        root = value.name
        _root = value.name
      }

      if (routesMap[value.name] && value.name === routesMap[value.name].name) {
        if (value.type === 0 || value.type === 1) {
          const parentNode = routesMap[value.name]
          value = Object.assign(value, {
            // name: parentNode.name,
            // component: () => import('@/views/singlePage/system-config/lock-the-car/index.vue'),
            //  require([`@/views/${url}`], resolve)
            // component: Promise.resolve().then(() => require('@/views/singlePage/system-config/lock-the-car/index.vue')),
            // () => import('@/views/singlePage/system-config/lock-the-car/index.vue')
            component: parentNode.component,
            // component: () => import(`${value.component}`),
            meta: Object.assign(parentNode.meta, {
              title: value.title,
              root: _root
            }),
            path: parentNode.path,
            redirect: parentNode.redirect
          })
          menuMap[value.name] = value
          if ('children' in value && value.children.length > 0) value.children = handleRecursion(value.children, root)
          return value
        } else {
          if (routesMap[value.name]) {
            value = Object.assign(value, {
              component: routesMap[value.name].component,
              path: value.name,
              meta: Object.assign(routesMap[value.name].meta, {
                title: value.title,
                root: _root
              })
            })
            return value
          }
        }
      }
    })
    // root = ''
    return newList
  }

  return {
    menuList: handleRecursion(data)
  }
}

/**
 * 处理菜单和按钮权限
 * @param arr
 *  btnMap 按钮map
 *  menuMap  菜单
 */
const handleMenuLimit = (arr: any) => {
  const btnMap = {}
  let menuMap = {}
  // 按钮权限
  const btnArr = arr.permission
  btnArr.forEach(element => {
    element.name && (btnMap[element.name] = element)
  })

  // false 加载远程菜单  true 加载本地菜单
  if (!isDevRouter) {
    const { menuList } = handleResetRouter(arr.limitTreeVo, menuMap)

    setRoutersAdd(menuList)
    routes.push(...menuList)
    store.commit('common/UPDATED_MENU_MAP', routes)
  } else {
    menuMap = flatRoutes(routes)
  }
  return { btnMap, menuMap }
}
const mutations = {
  SET_TOKEN: (state, token) => {
    state.token = token
  },
  SET_NAME: (state, name) => {
    state.name = name
  },
  SET_MENUMAP: (state, menuMap) => {
    state.userMenuMap = menuMap
  },
  SET_BTNMAP: (state, btnMap) => {
    state.userBtnMap = btnMap
  }
}
const actions = {
  /**
   * 登录
   * @param isAdmin 区分管理员
   */
  login({ commit }, userInfo) {
    const { code, mobile, username, password, isAdmin, validateCode } = userInfo
    return new Promise((resolve, reject) => {
      const p: any = isAdmin
        ? { accountName: username, password }
        : { mobile, smsCode: code }

      if (validateCode)p.validateCode = validateCode
      login(p)
        .then((response: any) => {
          if (response.code === 901) {
            resolve(response)
            return false
          }
          const { data } = response
          commit('SET_TOKEN', data.token)
          setToken(data.token)
          setLocalUserInfo(JSON.stringify(data))
          commit('SET_NAME', data.nickname)
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },
  // 确认登录
  confirmLogin({ commit }, p) {
    // cosnt {type,tmpToken,upgradeToken} = p
    return new Promise<void>((resolve, reject) => {
      confirmLoginAPI(p)
        .then((res: any) => {
          const { data } = res
          commit('SET_TOKEN', data.token)
          setToken(data.token)
          setLocalUserInfo(JSON.stringify(data))
          commit('SET_NAME', data.nickname)
          resolve(data)
        })
        .catch(error => {
          reject(error)
        })
    })
  },

  /**
   * 登出
   */
  loginOut({ commit }) {
    return new Promise<void>(resolve => {
      commit('SET_TOKEN', '')
      commit('SET_MENUMAP', '')
      commit('SET_BTNMAP', '')
      resetRouter()
      loginOut().then(() => {
        removeToken()
        resolve()
        for (const key in config) {
          localStorage.removeItem(config[key])
        }
      })
    })
  },
  /**
   * 获取权限菜单
   */
  getMenuResource({ commit }) {
    return new Promise<void>((resolve, reject) => {
      getResource()
        .then(res => {
          const { btnMap, menuMap } = handleMenuLimit(res.data)
          commit('SET_MENUMAP', menuMap)
          commit('SET_BTNMAP', btnMap)
          resolve(res.data)
        })
        .catch(err => {
          console.log(err)
          reject(err)
        })
    })
  }
}
export default {
  namespaced: true,
  state: {
    token: '',
    name: '',
    avatar: '',
    companyLogo: require('@/assets/logo.png'),
    userMenuMap: {},
    userBtnMap: {}
  },
  mutations,
  actions
}
