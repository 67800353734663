<template>
  <div :class="['main']">
    <main-navbar />
    <div class="main-content--t">
      <main-sidebar />
      <main-content />
    </div>
  </div>
</template>
<script>
import MainNavbar from '@c/main-navbar'
import MainSidebar from '@c/main-siderbar'
import MainContent from '@c/main-content'
import debounce from 'lodash/debounce'
import { useStore } from 'vuex'
// import config from '@/config'
export default {
  name: 'Main',
  components: {
    MainNavbar,
    MainSidebar,
    MainContent
  },
  setup () {
    const store = useStore()
    // 窗口改变大小
    const windowResizeHandle = () => {
      store.state.sidebarFold = document.documentElement.clientWidth <= 992 || false
      window.addEventListener('resize', debounce(() => {
        store.state.sidebarFold = document.documentElement.clientWidth <= 992 || false
      }, 150))
    }
    // window.addEventListener('beforeunload', () => {
    //   localStorage.setItem(config.localVistedViews, JSON.stringify(store.state.common.visitedViews))
    //   localStorage.setItem(config.localCachedViews, JSON.stringify(store.state.common.cachedViews))
    // })
    // store.dispatch('common/replaceVistedViews', JSON.parse(localStorage.getItem(config.localVistedViews)) || [])
    // store.dispatch('common/replaceCachedViews', JSON.parse(localStorage.getItem(config.localCachedViews)) || [])
    // 执行
    windowResizeHandle()
  }
}
</script>
<style scoped>
.main-content--t {
  display: flex;
  height: calc( 100vh - 64px);
  min-width: 1200px;
}
</style>
