<!--
 * @Descripttion: 
 * @Author: panyufeng
 * @Date: 2024-06-14 15:36:51
 * @LastEditors: panyufeng
 * @LastEditTime: 2024-10-15 14:24:53
-->
<template>
  <div class="main-content" id="main-content">
    <tags-view></tags-view>
    <div style="height: 100%; overflow: auto; margin-top: 1px">
      <router-view v-slot="{ Component }" class="router-view--content">
        <keep-alive :include="cachedViews">
          <component :is="Component" />
        </keep-alive>
      </router-view>
    </div>
  </div>
</template>
<script>
// import { isURL } from '@/utils/validate'
import { computed } from 'vue'
import { useStore } from 'vuex'
import tagsView from './tagView'

export default {
  name: 'Main-content',
  components: { tagsView },
  setup() {
    const store = useStore()
    const cachedViews = computed(() => store.state.common.cachedViews)

    return {
      cachedViews,
    }
  },
}
</script>
<style lang="scss" scoped>
.router-view--content {
  background: #fff;
  min-height: calc(100vh - 152px);
  width: 100%;
  overflow-x: hidden;
}
</style>
