import Home from '../../views/Home.vue'
import Main from '../../views/main.vue'
export default [
  {
    path: '/',
    name: 'home',
    component: Main,
    redirect: '/Home',
    meta: {
      notCache: true,
      title: '工作台'
    },
    children: [
      {
        path: 'Home',
        name: 'Home',
        component: Home,
        meta: {
          notCache: true,
          title: '工作台',
          root: 'home',
          affix: true
        }
      }
    ]
  }
]
