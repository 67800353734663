/*
 * @Descritption: 项目内配置文件
 * @Author: yuwenchao
 * @Date: 2021-03-25 10:02:36
 * @LastEditors: wangzhenyu
 * @LastEditTime: 2021-03-25 15:05:54
 */
export default {
  project: { // 项目基本信息
    projcetName: 'taxi-admin'
  },
  homeRoute: 'Home',
  systemKey: 'taxi-admin',
  storageUserKey: 'taxi-admin-user-info',
  TokenKey: 'taxi-admin-token',
  localVistedViews: 'taxi-admin-vistedViews',
  localCachedViews: 'taxi-admin-cachedViews'
}
