import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: ['main-sidebar'] }
const _hoisted_2 = { class: "main-sidebar--menu" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sub_menu = _resolveComponent("sub-menu")
  const _component_el_menu = _resolveComponent("el-menu")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("div", _hoisted_2, [
      _createVNode(_component_el_menu, {
        "default-active": _ctx.$store.state.common.siderbarMenuActiveName,
        "unique-opened": true,
        collapseTransition: false,
        class: "aui-sidebar__menu"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.$store.state.common.siderbarMenuList, (menu) => {
            return (_openBlock(), _createBlock(_Fragment, {
              key: menu.name
            }, [
              (
              (_ctx.$store.state.user.userMenuMap[menu.name] &&
                menu.meta && !menu.meta.isMenuHide) ||
                menu.name === 'Home' ||
                ($setup.developmentMode && !menu.meta.isMenuHide)
            )
                ? (_openBlock(), _createBlock(_component_sub_menu, {
                    key: 0,
                    menu: menu
                  }, null, 8, ["menu"]))
                : _createCommentVNode("", true)
            ], 64))
          }), 128))
        ]),
        _: 1
      }, 8, ["default-active"])
    ])
  ]))
}