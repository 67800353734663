/*
 * @Description:企业管理
 * @Author: rongcheng
 * @Date: 2021-03-30 10:25:33
 * @LastEditors: panyufeng
 * @LastEditTime: 2024-10-15 14:26:42
 */

import Main from '../../views/main.vue'
import keepAliveContent from '@/components/layouts/keepAlive/index.vue'
// import Home from '../../views/Home.vue'
const rootName = 'enterprise'


export default [
  {
    path: '/enterprise',
    name: rootName,
    component: Main,
    meta: {
      notCache: true,
      title: '企业管理'
    },
    children: [
      {
        path: 'enterprise-info',
        name: 'enterprise-info',
        component: keepAliveContent,
        // redirect: '/enterprise/enterprise-info/enterprise-info-query',
        redirect: { name: 'enterprise-info-query' },
        // component: { template: '<router-view></router-view>' },

        meta: {
          notCache: true,
          title: '企业信息',
          root: rootName
        },
        children: [
          {
            path: 'query',
            name: 'enterprise-info-query',

            component: () => import('@/views/singlePage/enterprise-management/enterprise/index.vue'),
            meta: {
              notCache: false,
              title: '企业信息列表',
              isMenuHide: true,
              root: rootName
            }
          },
          {
            path: 'enterprise-info-add',
            name: 'enterprise-info-add',
            component: () => import('@/views/singlePage/enterprise-management/enterprise/create-enterprise.vue'),
            meta: {
              notCache: true,
              title: '新建企业',
              root: rootName,
              isMenuHide: true
            }
          },
          {
            path: 'enterprise-info-edit/:uuid',
            name: 'enterprise-info-edit',
            component: () => import('@/views/singlePage/enterprise-management/enterprise/edit-enterprise.vue'),
            meta: {
              notCache: true,
              title: '编辑企业',
              root: rootName,
              isMenuHide: true
            }
          },
          {
            path: 'detail/:uuid',
            name: 'enterprise-info-detail',
            component: () =>
              import(
                '@/views/singlePage/enterprise-management/enterprise/detail-enterprise.vue'
              ),
            meta: {
              notCache: true,
              title: '企业详情',
              root: rootName,
              isMenuHide: true
            }
          }
        ]
      },
      {
        path: 'enterprise-admin',
        name: 'enterprise-admin',
        component: () =>
          import(
            '@/views/singlePage/enterprise-management/administrator/index.vue'
          ),
        meta: {
          notCache: true,
          title: '企业管理员',
          root: rootName
        }
      },
      {
        path: 'enterprise-permission',
        name: 'enterprise-permission',
        component: () =>
          import(
            '@/views/singlePage/enterprise-management/permission/index.vue'
          ),
        meta: {
          notCache: true,
          title: '前台权限列表',
          root: rootName
        }
      },
      {
        path: 'enterprise-role',
        name: 'enterprise-role',
        component: () =>
          import(
            '@/views/singlePage/enterprise-management/role/index.vue'
          ),
        meta: {
          notCache: true,
          title: '前台角色管理',
          root: rootName
        }
      }
    ]
  }
]
