/*
 * @Description: config
 * @Author: yaoxiaoxiao
 * @Date: 2021-03-22 14:07:18
 * @LastEditors: yaoxiaoxiao
 * @LastEditTime: 2021-04-12 10:02:57
 */

/**
 * @description: 特殊字符校验
 * @param {string} str
 * @return {*}
 */

// 暂不使用

// const checkSpecificKey = (str: string): boolean => {
//   const specialKey = "[`~!#$^&*()=|{}':;',\\[\\].<>/?~@！#￥……&*（）——|{}【】‘；：”“'。，、？]‘'"
//   for (let i = 0; i < str.length; i++) {
//     if (specialKey.indexOf(str.substr(i, 1)) !== -1) {
//       return false
//     }
//   }
//   return true
// }

/**
 * @description: 数字校验
 * @param {string} val
 * @return {*}
 */

// 暂不使用

// const isNumber = (val: string): boolean => {
//   const regPos = /^\d+(\.\d+)?$/ // 非负浮点数
//   const regNeg = /^((([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/ // 负浮点数
//   if (regPos.test(val) || regNeg.test(val)) {
//     return true
//   } else {
//     return false
//   }
// }

/**
 * @description: 账户校验
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 * @return {*}
 */
const validateUserName = (rule, value, callback) => {
  if (!value) {
    return callback(new Error('必填'))
  } else if (value.length > 53) {
    return callback(new Error('账户过长'))
  } else {
    return callback()
  }
}

/**
 * @description: 密码校验
 * @param {*} rule
 * @param {*} value
 * @param {*} callback
 * @return {*}
 */
const validatePass = (rule, value, callback) => {
  const len = value?.length
  if (!value) {
    return callback(new Error('必填'))
  } else if (len < 8) {
    return callback(new Error('请输入至少8位登录密码'))
  } else if (len > 16) {
    return callback(new Error('登录密码过长'))
  } else {
    return callback()
  }
}

// 表单校验规则
export const rules = {
  mobile: [{
    required: true,
    message: '必填',
    trigger: 'blur'
  }, {
    pattern: /^[1][0-9]{10}$/,
    message: '请输入11位手机号',
    trigger: 'blur'
  }],
  code: [{
    required: true,
    message: '必填',
    trigger: 'blur'
  }, {
    pattern: /^[0-9]{6}$/,
    message: '请输入6位短信验证码',
    trigger: 'blur'
  }
  ],
  username: [{
    validator: validateUserName,
    trigger: ['blur', 'change']
  }],
  password: [{
    validator: validatePass,
    trigger: ['blur', 'change']
  }],
  validateCode: [{
    required: true,
    message: '请输入正确验证码',
    trigger: ['blur', 'change']
  }]
}
