/*
 * @Description: 系统维护路由表
 * @Author: yaoxiaoxiao
 * @Date: 2021-03-30 10:18:30
 * @LastEditors: yaoxiaoxiao
 * @LastEditTime: 2021-04-13 11:29:35
 */

const rootName = 'basics-manager'

export default {
  path: 'system-settings',
  name: 'system-settings',
  component: { template: '<div><router-view></router-view></div>' },
  meta: {
    notCache: true,
    title: '系统维护',
    root: rootName
  },
  children: [
    {
      path: 'log',
      name: 'log',
      component: () => import('@/views/singlePage/basics-manager/maintenance/log/index.vue'),
      meta: {
        notCache: true,
        title: '操作日志',
        root: rootName
      }
    },
    {
      path: 'message',
      name: 'message',
      component: () => import('@/views/singlePage/basics-manager/maintenance/message/index.vue'),
      meta: {
        notCache: true,
        title: '短信配置',
        root: rootName
      }
    },
    {
      path: 'parameter',
      name: 'parameter',
      component: () => import('@/views/singlePage/basics-manager/maintenance/parameter/index.vue'),
      meta: {
        notCache: true,
        title: '系统参数',
        root: rootName
      }
    }
  ]
}
