
import SubMenu from './main-siderbar-sub-menu.vue'
import { computed, onMounted, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
export default {
  name: 'Main-sidebar',
  components: {
    SubMenu
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const moveActiveMenuItem = view => {
      store.dispatch('common/moveActiveMenu', view)
    }
    const developmentMode = computed(() => store.state.common.developmentMode)
    watch(
      () => router.currentRoute.value,
      value => {
        moveActiveMenuItem(value)
      }
    )
    onMounted(() => moveActiveMenuItem(router.currentRoute.value))
    return {
      developmentMode
    }
  }
}
