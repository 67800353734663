/*
 * @Description:
 * @Author: rongcheng
 * @@后台人员: xxx
 * @Date: 2022-04-11 17:11:10
 * @LastEditors: rongcheng
 * @LastEditTime: 2022-04-11 17:17:35
 */

import Main from '../../views/main.vue'
const rootName = 'ceshi-heng'
export default [
  {
    path: '/ceshi-heng',
    name: rootName,
    component: Main,
    meta: {
      notCache: true,
      title: '菜单1',
      icon: 'el-icon-setting'
    },
    children: [
      {
        path: 'driver-index1',
        name: 'test1',
        component: { template: '<div class="main-bg">测试2</div>' },
        meta: {
          notCache: true,
          title: '测试1',
          root: rootName,
          icon: 'el-icon-s-check'
        }
      },
      {
        path: 'driver-index1',
        name: 'test2',
        component: { template: '<div class="main-bg">测试2</div>' },
        meta: {
          notCache: true,
          title: '测试2',
          root: rootName,
          icon: 'el-icon-s-check'
        }
      }
    ]
  }
]
