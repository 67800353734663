import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'
import { getToken, removeToken } from '@/utils/auth'
import { setBaseUrl } from '@/config/url'
import router from '@/router'
// 创建axios实例
const service = axios.create({
  // 在请求地址前面加上baseURL
  baseURL: setBaseUrl().uploadUrl + '/ccclubs-file-center',
  timeout: 5000
})

// 请求拦截
service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    config.headers.token = getToken()
    return config
  },
  (error: any) => {
    // 请求错误的统一处理
    console.log(error)
    return Promise.reject(error)
  }
)
// 响应拦截器
service.interceptors.response.use(
  (response: AxiosResponse) => {
    const res = response.data
    if (res.code !== 200 || !res.success) {
      ElMessage.error(response.data.msg)
      if (res.code === 10001) {
        // to re-login
        removeToken()
        router.push({ path: '/login' })
      }
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
  },
  (error: any) => {
    if (typeof error === 'object') {
      ElMessage.error(JSON.stringify(error.message))
    }
    return Promise.reject(error)
  }
)

export default service
