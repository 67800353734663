/*
 * @Description: 系统设置模块路由
 * @Author: yeyouxi
 * @Date: 2021-03-16 10:25:28
 * @LastEditTime: 2022-04-25 13:36:49
 * @LastEditors: rongcheng
 */
import Main from '../../views/main.vue'
import maintenance from './modules/maintenance'
const rootName = 'basics-manager'

export default [
  {
    path: '/basics-manager',
    name: rootName,
    component: Main,
    meta: {
      notCache: true,
      title: '基础管理'
    },
    children: [
      {
        path: 'authority',
        name: 'authority',
        component: { template: '<div><router-view></router-view></div>' },
        meta: {
          notCache: true,
          title: '权限管理',
          root: rootName
        },
        children: [
          {
            path: 'user-manager',
            name: 'user-manager',
            component: () => import('@/views/singlePage/basics-manager/authority/user-manager/index.vue'),
            meta: {
              notCache: true,
              title: '用户管理',
              root: rootName
            }
          },
          {
            path: 'role',
            name: 'role',
            component: () => import('@/views/singlePage/basics-manager/authority/role/index.vue'),
            meta: {
              notCache: true,
              title: '角色管理',
              root: rootName
            }
          },
          {
            path: 'white-list',
            name: 'white-list',
            component: () => import('@/views/singlePage/basics-manager/white/index.vue'),
            meta: {
              notCache: true,
              title: '白名单管理',
              root: rootName
            }
          }
        ]
      },
      maintenance,
      {
        path: 'auditLog',
        name: 'auditLog',
        component: () => import('@/views/singlePage/basics-manager/auditLog/index.vue'),
        meta: {
          notCache: true,
          title: '审计日志',
          root: rootName
          // isMenuHide: true
        }
      }
    ]
  }
]
