
document.title = '收银台'
import { defineComponent, reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import { getUrlParams } from '@/utils/index'
export default defineComponent({
    name: '',
    components: {},
    setup() {
        const route = useRoute()
        console.log(route.query)
        console.log(getUrlParams(location.href))

        const data = reactive({
            p:getUrlParams(location.href)
        })

        return {
            ...toRefs(data)
        }
    }
})
