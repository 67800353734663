
import { defineComponent, reactive, toRefs, watchEffect } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  name: '',
  setup() {
    const route = useRoute()
    const data = reactive({
      include: route.meta.notCache === false ? route.name : 'test111'
    })
    watchEffect(() => {
      if (route.meta.notCache === false) data.include = route.name
    })

    return {
      ...toRefs(data)
    }
  }
})
