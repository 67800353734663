import { createStore } from 'vuex'
// import createPersistedState from 'vuex-persistedstate'

import common from './modules/common'
import user from './modules/user'
interface State {
  common: any;
  user: any;
  [propName: string]: any;
}
export default createStore({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    common,
    user
  }
  // plugins: [createPersistedState()]
})
