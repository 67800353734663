import store from '@/store'

interface State {
  common: any;
  user: any;
  [propName: string]: any;
}
export default {
  mounted(el, binding) {
    if (!(store.state as State).user.userBtnMap[binding.value]) {
      el.style.display = 'none'
    }
  }
}
