import { createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, withCtx as _withCtx, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  class: "icon-svg aui-sidebar__menu-icon",
  "aria-hidden": "true"
}
const _hoisted_2 = {
  class: "icon-svg aui-sidebar__menu-icon",
  "aria-hidden": "true"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_sub_menu = _resolveComponent("sub-menu")
  const _component_el_submenu = _resolveComponent("el-submenu")
  const _component_el_menu_item = _resolveComponent("el-menu-item")

  return ($setup.typeChildren($props.menu))
    ? (_openBlock(), _createBlock(_component_el_submenu, {
        key: 0,
        "popper-append-to-body": false,
        index: $props.menu.name
      }, {
        title: _withCtx(() => [
          (_openBlock(), _createBlock("svg", _hoisted_1, [
            _createVNode("use", {
              "xlink:href": `#${$props.menu.icon}`
            }, null, 8, ["xlink:href"])
          ])),
          _createVNode("span", null, _toDisplayString($props.menu.meta.title), 1)
        ]),
        default: _withCtx(() => [
          (_openBlock(true), _createBlock(_Fragment, null, _renderList($props.menu.children, (item) => {
            return (_openBlock(), _createBlock(_component_sub_menu, {
              key: item.name,
              menu: item
            }, null, 8, ["menu"]))
          }), 128))
        ]),
        _: 1
      }, 8, ["index"]))
    : ($setup.validCondition($props.menu))
      ? (_openBlock(), _createBlock(_component_el_menu_item, {
          key: 1,
          onClick: _cache[1] || (_cache[1] = ($event: any) => ($setup.gotoRouteHandle($props.menu.name))),
          index: $props.menu.name
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock("svg", _hoisted_2, [
              _createVNode("use", {
                "xlink:href": `#${$props.menu.icon}`
              }, null, 8, ["xlink:href"])
            ])),
            _createVNode("span", null, _toDisplayString($props.menu.meta.title), 1)
          ]),
          _: 1
        }, 8, ["index"]))
      : _createCommentVNode("", true)
}