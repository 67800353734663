
import { defineComponent, reactive, ref, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import { getUrlParams } from '@/utils/index'
import axios from 'axios'

document.title = '收银台'

export default defineComponent({
    name: '',
    components: {},
    setup() {
        const route = useRoute()
        console.log(route.query)
        console.log(getUrlParams(location.href))

        const data = reactive({
            base: ref<any>(getUrlParams(location.href)),
            href: location.href,
            form:{
                action:'',
                value:''
            },
            formRef:null,
            loading: true
        })

        const onsubmit = () => {
            const payInfo = {
                billIds: String(data.base.billIds).split(','), // 账单id
                // payExpire: 900, // 默认15分钟
                wxOpenId: data.base.wxOpenId, //小程序用户唯一编号
                billType: data.base.billType
            }
            axios.post(`${data.base.base}bank/qrcode/jftPay`,
                { ...payInfo },
                {
                    headers: {
                        token: data.base.token
                    }
                }).then(res => {
                    const r = res.data.data
                    console.log(r)
                    data.form.action = r.action
                    data.form.value = r.inputValue

                    console.log(data.formRef)
                    setTimeout(()=>{
                        data.formRef.submit()
                        // document.forms[0].submit();
                    },0)
                })
        }

        onsubmit()

        return {
            ...toRefs(data),

        }
    }
})
