/*
 * @Description:
 * @Author: yaoxiaoxiao
 * @Date: 2021-03-16 17:10:16
 * @LastEditors: yyx
 * @LastEditTime: 2023-04-25 11:03:12
 */
import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import basic from './basic'
// import components from './components'
import basicsManager from './basics-manager'
// import monitoringSystem from './monitoring-system'
import operationManagement from './operation-management'
import enterpriseManagement from './enterprise-management'
import systemConfig from './system-config'
import test from './test'
import Login from '@/views/singlePage/login/index.vue'
import { getToken } from '@/utils/auth'
import { ElMessage } from 'element-plus'
import config from '@/config'
import jumpminipay from '@/views/jumpmini/jftPay/index.vue'
import formSubmit from '@/views/jumpmini/formSubmit/index.vue'

/**
 * meta字段说明
 * noCache 用于缓存策略 默认不缓存
 * root 用于快速定位当前根目录
 * affix 用于tagview 是否固定显示 默认不固定
 */

export let routes: Array<RouteRecordRaw> = [
  ...basic,
  {
    name: 'login',
    path: '/login',
    component: Login,
    meta: {
      title: '登录',
      isMenuHide: true
    }
  },
  {
    name: 'jumpminipay',
    path: '/jumpminipay',
    component: jumpminipay,
    meta: {
      title: '',
      isMenuHide: true
    }
  },
  {
    name: 'formSubmit',
    path: '/formSubmit',
    component: formSubmit,
    meta: {
      title: '',
      isMenuHide: true
    }
  },
  {
    path: '/:catchAll(.*)',
    name: '404',
    component: () => import('@/views/404.vue'),
    meta: { title: '404未找到', isMenuHide: true }
  }
]

export const trendsRouter = [ // 动态渲染的router
  // ...components,
  // ...basic,
  ...enterpriseManagement,
  ...operationManagement,
  ...basicsManager,
  ...systemConfig,
  ...test
]
// 现在启用本地router // 本地加载本地route
export const isDevRouter = process.env.NODE_ENV === 'development' && false

if (isDevRouter) {
  routes.push(...trendsRouter)
}
const router = createRouter({
  history: createWebHashHistory(),
  routes
})
export const setRoutersAdd = (list) => {
  if (Array.isArray(list)) {
    list.forEach(item => {
      ;(router as any).addRoute(item)
    })
  }
}
export const setRoutersRemove = (list) => {
  if (Object.prototype.toString.call(list) === '[object Array]') {
    list.forEach(item => {
      ;(router as any).removeRoute(item.name)
      !!item.children && setRoutersRemove(item.children)
    })
  }
  if (Object.prototype.toString.call(list) === '[object Object]') {
    Object.keys(list).forEach(item => {
      ;(router as any).removeRoute(list[item].name)
      !!list[item].children && setRoutersRemove(list[item].children)
    })
  }
  if (Object.prototype.toString.call(list) === '[object String]') {
    ;(router as any).removeRoute(list)
  }
}
export function resetRouter () {
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  setRoutersRemove(trendsRouter)
  routes = router.getRoutes().filter(item => item.name !== 'Home')
}
// eslint-disable-next-line
router.beforeEach((to, from, next) => {

  if (to.meta.title) {
    document.title = String(to.meta.title)
  }
  if (to.name === 'login' || to.name === 'jumpminipay' || to.name === 'formSubmit') {
    next()
  } else {
    if (!getToken()) {
      ElMessage.warning('请先登录')
      next({ name: 'login' })
    } else {
      const store = require('@/store').default
      const userMenuMap = store.state.user.userMenuMap
      const userBtnMap = store.state.user.userBtnMap

      if (userMenuMap && Object.keys(userMenuMap).length === 0) {
        store.dispatch('user/getMenuResource').then(() => {
          next(to.fullPath)
        }).catch(() => {
          next()
        })
      } else {
        if (userMenuMap[to.name] || to.name === config.homeRoute) {
          next()
        } else {
          if (process.env.NODE_ENV === 'development') {
            next()
            // debugger
          } else {
            if (userBtnMap[to.name]) {
              next()
            } else {
              ElMessage.error('暂无权限')
            }
          }
        }
      }
    }
  }
})

export default router
