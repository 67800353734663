import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { ElMessage, ElMessageBox } from 'element-plus'
import { getToken, removeToken } from '@/utils/auth'
import { setBaseUrl } from '@/config/url'
import router from '@/router'
// 创建axios实例
const service = axios.create({
  // 在请求地址前面加上baseURL
  baseURL: setBaseUrl().baseUrl + '/ccclubs-admin',
  timeout: 30000
})

// 请求拦截
service.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    config.headers.token = getToken()
    return config
  },
  (error: any) => {
    // 请求错误的统一处理
    console.log(error)
    return Promise.reject(error)
  }
)
// 响应拦截器
service.interceptors.response.use(
  (response: AxiosResponse) => {
    const res = response.data
    if (res.code !== 200 || !res.success) {
      ElMessage.error(response.data.msg)
      if (res.code === 10001) {
        ElMessageBox.confirm('因长时间没有操作，为了您的数据安全，请重新登录', '提示', {
          confirmButtonText: '重新登录'
        }).then(() => {
          // to re-login
          removeToken()
          router.push({ path: '/login' })
        }).catch(() => {})
      } else if (res.code === 901) {
        return res
      }
      return Promise.reject(new Error(res.msg || 'Error'))
    } else {
      return res
    }
  },
  (error: any) => {
    if (typeof error === 'object') {
      console.log(error)

      ElMessage.error(JSON.stringify(error.response.data.msg || error.message))
    }
    return Promise.reject(error)
  }
)

export default service
