import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import installElement from '@/plugins/elementPlus'
import directives from '@/directives'
import '@/styles/scss/aui.scss'
const app = createApp(App)

for (const key in directives) {
  app.directive(key, directives[key])
}
installElement(app)

app
  .use(store)
  .use(router)
  .mount('#app')
