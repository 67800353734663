/*
 * @Description:
 * @Author: rongcheng
 * @@后台人员: xxx
 * @Date: 2021-03-31 09:45:04
 * @LastEditors: rongcheng
 * @LastEditTime: 2022-04-01 10:07:32
 */
import { useStore } from 'vuex'
/**
 * 构造树型结构数据
 * @param {*} source 数据源
 * @param {*} id id字段 默认 'id'
 * @param {*} parentId 父节点字段 默认 'parentId'
 * @param {*} children 孩子节点字段 默认 'children'
 */
export function treeData(source: any, id?: any, parentId?: any, children?: any) {
  const cloneData = JSON.parse(JSON.stringify(source))
  id = id || 'id'
  parentId = parentId || 'parentId'
  children = children || 'children'

  return cloneData.filter((father: any) => {
    const branchArr = cloneData.filter(child => father[id] === child[parentId])
    // eslint-disable-next-line no-unused-expressions
    branchArr.length > 0 ? father[children] = branchArr : ''
    return father[parentId] === '0' // 如果顶层不是parentId='0'，请自行修改
  })
}

/**
 * @description: 枚举转 select option
 * @param {*} Enum 枚举类型
 * @return {*} Array
 */
export const enumToOption = (Enum: any): any[] => {
  const option: any[] = []
  for (const n in Enum) {
    if (typeof Enum[n] === 'number') {
      option.push({
        label: n,
        value: Enum[n]
      })
    }
  }
  return option
}

export const btnName = (logotype) => {
  const store = useStore()
  if (typeof (store.state.user.userBtnMap[logotype]) === 'object') {
    return store.state.user.userBtnMap[logotype].title
  }
  return ''
}

/**
 * @description: 数字转成大写汉字
 * @param {*} n 价钱
 * @return {*} Number
 */

export const digitUppercase = (n) => {
  if (isNaN(n)) { // 判断是否为数字
    return
  }

  const fraction = ['角', '分']
  const digit = [
    '零', '壹', '贰', '叁', '肆',
    '伍', '陆', '柒', '捌', '玖'
  ]
  const unit = [
    ['元', '万', '亿'],
    ['', '拾', '佰', '仟']
  ]
  const head = n < 0 ? '欠' : ''
  n = Math.abs(n)
  let s = ''
  for (let i = 0; i < fraction.length; i++) {
    s += (digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]).replace(/零./, '')
  }
  s = s || '整'
  n = Math.floor(n)
  for (let i = 0; i < unit[0].length && n > 0; i++) {
    let p = ''
    for (let j = 0; j < unit[1].length && n > 0; j++) {
      p = digit[n % 10] + unit[1][j] + p
      n = Math.floor(n / 10)
    }
    s = p.replace(/(零.)*零$/, '').replace(/^$/, '零') + unit[0][i] + s
  }
  return head + s.replace(/(零.)*零元/, '元')
    .replace(/(零.)+/g, '零')
    .replace(/^整$/, '零元整')
}

// 获取url参数
export const getUrlParams = (url) => {
  if (url.indexOf('?') === -1) return ''
  // 通过 ? 分割获取后面的参数字符串
  const urlStr = url.split('?')[1]
  // 创建空对象存储参数
  const obj = {}
  // 再通过 & 将每一个参数单独分割出来
  const paramsArr = urlStr.split('&')
  for (let i = 0, len = paramsArr.length; i < len; i++) {
    // 再通过 = 将每一个参数分割为 key:value 的形式
    const arr = paramsArr[i].split('=')
    obj[arr[0]] = arr[1]
  }
  return obj
}