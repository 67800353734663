
import { ElMessage } from 'element-plus'
import { defineComponent, reactive, ref, toRefs, watchEffect } from 'vue'
export default defineComponent({
  name: 'changePassword',
  props: {
    modelValue: {
      type: Boolean,
      required: true,
      default: false
    }
  },
  setup(props, { emit }) {
    const required = {
      required: true,
      message: '必填',
      trigger: ['blur', 'change']
    }

    const contentLength = {
      min: 6,
      max: 12,
      message: '请输入6-12位密码',
      trigger: ['blur', 'change']
    }

    const refFrom = ref<HTMLElement | any>(null)

    const state = reactive({
      visible: false,
      loading: false,
      title: '修改密码',
      formData: ref<any>({}),
      rules: {
        oldPassword: [required],
        newPassword: [required, contentLength
          // {
          //   // pattern: /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()<>,.?-_+=`~])[0-9a-zA-Z!@#$%^&*()<>,.?-_+=`~]{6,18}$/,
          //   pattern: /^(?=.*[0-9])(?=.*[a-zA-Z])(?![!@#$%^&*()<>,.?-_+=`~])[0-9a-zA-Z!@#$%^&*()<>,.?-_+=`~]{6,12}$/,
          //   message: '请输入字母与数字组合的密码，建议增加符号，不区分大小写',
          //   trigger: ['blur', 'change']
          // }
        ],
        confirmPassword: [required, contentLength, {
          validator: (rule, value, callback) => {
            if (value !== state.formData?.newPassword) {
              return callback(new Error('两次新密码不一致'))
            } else {
              return callback()
            }
          },
          trigger: ['blur', 'change']
        }]
      }
    })

    watchEffect(() => {
      state.visible = props.modelValue
    })

    const onCancel = () => {
      state.visible = false
      state.formData = {}
      emit('update:modelValue', false)
    }

    // const onSubmit = async p => {
    //   let requestAPI = addAPI
    //   if (props.isType === 'edit') requestAPI = editAPI
    //   const [err, res, msg] = await toResult(requestAPI(p))
    //   if (err) return
    //   console.log(res)
    //   ElMessage.success(msg || '成功')
    //   onCancel()
    // }

    const onValidate = () => {
      console.log(refFrom.value)
      refFrom.value.validate((valid: boolean) => {
        if (!valid) return
        ElMessage.info('修改密码成功，将退出请重新登录')
        // onSubmit({
        //   ...state.formData
        // })
      })
    }

    return {
      ...toRefs(state),
      refFrom,
      onCancel,
      onValidate
    }
  }
})
