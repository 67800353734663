
import { useRouter } from 'vue-router'
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import config from '@/config'
import { ElMessageBox } from 'element-plus'
import changePassword from '@/views/singlePage/change-password/index.vue'

export default {
  name: 'Main-navbar',
  components: { changePassword },
  setup() {
    const router = useRouter()
    const store = useStore()
    const userInfo = JSON.parse(localStorage.getItem(config.storageUserKey))
    const nickname = computed(
      () => store.state.user.name || (userInfo && userInfo.nickname)
    )
    const developmentMode = computed(() => store.state.common.developmentMode)

    const isChangePassword = ref(false)

    // const state = reactive({ menuActive: 'home' })
    // 退出
    const logoutHandle = () => {
      ElMessageBox
        .confirm('退出登录', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        })
        .then(() => {
          // 清除登录信息
          store.dispatch('user/loginOut')
          router.push({ name: 'login' })
          setTimeout(() => {
            window.location.reload()
          }, 0)
        })
      // .catch(() => {})
    }

    const checkDeepChildren = (target, menuMap) => {
      let result = ''
      if (Array.isArray(target)) {
        for (const route of target) {
          if (route.children) {
            // result = checkDeepChildren(route.children, menuMap)
            if (result) break
          } else {
            if (menuMap[route.name]) {
              // result = route.name
              break
            }
          }
        }
      } else {
        console.log(target, 'route')
        if (menuMap[target.name]) {
          result = target.name
        }
      }
      return result
    }

    const navMenuHandle = (name) => {
      // store.dispatch('common/moveActiveMenu', )
      store.state.common.siderbarMenuList = store.state.common.totalMenuMap[name]
      store.state.common.topbarMenuActiveName = name

      const tab = store.state.common.visitedViews.filter(item => {
        return name === (item.meta && item.meta.root)
      })[0]
      if (!tab) {
        store.state.common.siderbarMenuActiveName = checkDeepChildren(
          store.state.common.siderbarMenuList,
          store.state.user.userMenuMap
        )
        router.push({
          name: store.state.common.siderbarMenuActiveName
        })
      } else {
        store.state.common.siderbarMenuActiveName = tab.name
        router.push({ path: tab.fullPath })
      }
    }

    const changePwsHandle = () => {
      isChangePassword.value = !isChangePassword.value
    }
    return {
      logoutHandle,
      navMenuHandle,
      nickname,
      developmentMode,
      changePwsHandle,
      isChangePassword
    }
  }
}
