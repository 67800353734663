
import { ElMessage, ElMessageBox } from 'element-plus'
import { defineComponent, reactive, toRefs, ref, onMounted } from 'vue'
import { rules } from '../config'
import { resetRouter, isDevRouter } from '@/router/index'
import { useStore } from 'vuex'
import { getMessageCode, generateValidateCodeAPI } from '@/api/common'
import { useRouter } from 'vue-router'
import config from '@/config'
import { toResult } from '@/utils/toResult'

export default defineComponent({
  name: 'loginCore',
  setup() {
    // 文字描述
    const Text = {
      adminText: '超级管理员登录',
      userText: '普通用户登录',
      sendMsgText: '获取验证码'
    }
    const store = useStore()
    const router = useRouter()
    const data = reactive({
      // 表单校验规则
      rules,
      // 表单数据
      formData: {
        username: '',
        password: '',
        mobile: '',
        code: '',
        validateCode: ''
      },
      // 是否需要验证码 true-需要，false-不需要（密码错误超过两次返回）
      isNeedValidateCode: false,
      //  证码图片
      validImg: '',
      // 是否是管理员
      isAdmin: false,
      // 是否禁用短信发送按钮
      isSendMsgDisabled: true,
      // 是否禁用登录按钮
      isLoginBtnDisabled: true,
      // form dom
      formRef: ref<HTMLElement | null>(null),
      // 短信按钮文字
      sendMsgText: Text.sendMsgText,
      // 登录类型文字
      loginTypeText: Text.adminText
    })

    const handleValidateCode = async(p) => {
      const [err, res] = await toResult(generateValidateCodeAPI(p))
      if (err) return
      console.log(res)
      data.isNeedValidateCode = res.isNeedValidateCode
      data.validImg = res.validateCode
    }

    // 登录之后
    const LoginAfter = () => {
      store.dispatch('user/getMenuResource')
        .then((res) => {
          if (res.limitTreeVo.length > 0 || res.permission.length > 0) {
            router.push({ name: config.homeRoute })
          } else {
            ElMessage.warning('没有权限!')
          }
        })
      // store.dispatch('user/getMenuResource')
    }

    // 确认登录
    const loginConfirm = (p) => {
      const { tmpToken, upgradeToken } = p
      let type = 1
      data.isAdmin ? type = 1 : type = 2
      store.dispatch('user/confirmLogin', { tmpToken, upgradeToken, type })
        .then(res => {
          console.log(res)
          if (upgradeToken) LoginAfter()
        })
    }

    // 监听输入用户名
    const BlurUserName = () => {
      const p = {
        accountName: data.formData.mobile ? data.formData.mobile : data.formData.username,
        refresh: true
      }

      if (data.isAdmin) {
        if (data.formData.username) handleValidateCode(p)
      } else {
        if (data.formData.mobile) handleValidateCode(p)
      }
    }

    // 登录
    const handleLogin = () => {
      store
        .dispatch('user/login', { ...data.formData, isAdmin: data.isAdmin })
        .then((res) => {
          if (res.code === 901) {
            ElMessage.error(res.msg)
            BlurUserName()
            return false
          }
          // 是否继续登录：true-继续登录【请求确认】，false-取消登录【直接登录】，特么的这逻辑我都是蒙的
          if (res.upgradeToken) {
            ElMessageBox.confirm('您的账号已登录，如确定登录，原登陆终端将会自动登出。', '', {
              confirmButtonText: '确定登录',
              cancelButtonText: '取消登录',
              showClose: false,
              closeOnClickModal: false,
              closeOnPressEscape: false
            }).then(() => {
              // 确认登录
              loginConfirm(res)
            }).catch(() => {
              loginConfirm({ tmpToken: res.tmpToken, upgradeToken: false })

              for (const key in config) {
                localStorage.removeItem(config[key])
              }
            })
          } else {
            LoginAfter()
          }
        }).catch(err => {
          console.log(err)
        })
    }

    // 登录之前
    // const onSubmitBefore = async () => {
    //   const p: any = {}
    //   data.isAdmin ? p.accountName = data.formData.username : p.mobile = data.formData.mobile
    //   const [err, res] = await toResult(checkLoginStatusAPI(p))
    //   if (err) return
    //   // 账号是否已登录：true-是，false-否
    //   if (res.alreadyLogged) {
    //     ElMessageBox.confirm('您的账号已登录，如确定登录，原登陆终端将会自动登出。', '', {
    //       confirmButtonText: '确定登录',
    //       cancelButtonText: '取消登录'
    //     }).then(() => {
    //       handleLogin()
    //     })
    //   } else {
    //     handleLogin()
    //   }
    // }

    /**
     * @description: 执行登录
     * @param {*}
     * @return {void}
     */
    const onSubmit = () => {
      const ref: any = data.formRef
      ref.validate((vaild: boolean) => {
        if (vaild) {
          handleLogin()
        }
      })
    }

    /**
     * @description: 发送短信倒计时
     * @param {*}
     * @return {void}
     */
    const countdown = () => {
      data.isSendMsgDisabled = true
      let time = 60
      const setTimer = setInterval(() => {
        time--
        data.sendMsgText = `${time}s 后重试`
        if (time <= 0) {
          clearInterval(setTimer)
          data.sendMsgText = Text.sendMsgText
          data.isSendMsgDisabled = false
        }
      }, 1000)
    }

    /**
     * @description: 执行短信发送
     * @param {*} -
     * @return {void}
     */
    const handleSendMsg = () => {
      const ref: any = data.formRef
      ref.validateField('mobile', valid => {
        if (!valid) {
          getMessageCode({ mobile: data.formData.mobile }).then(() => {
            ElMessage.success('短信已发送')
            // 执行倒计时
            countdown()
          })
        }
      })
    }

    /**
     * @description: 重置状态
     * @param {*}
     * @return {void}
     */
    const resetState = () => {
      data.isSendMsgDisabled = true
      data.isLoginBtnDisabled = true
      data.formData = {
        username: '',
        password: '',
        mobile: '',
        code: '',
        validateCode: ''
      }
    }

    /**
     * @description: 切换登录类型
     * @param {*}
     * @return {*}
     */
    const toggleLoginType = () => {
      // if (data.isNeedValidateCode) handleValidateCode()

      data.isAdmin = !data.isAdmin
      data.isAdmin
        ? (data.loginTypeText = Text.userText)
        : (data.loginTypeText = Text.adminText)
      resetState()
    }

    /**
     * @description: input text 仅能输入 数字
     * @param {string} name
     * @param {string} value
     * @return {void}
     */
    const onlyNumber = (name: string, value: string) => {
      data.formData[name] = value.replace(/[^0-9-]+/, '')
    }

    /**
     * @description: 监听手机号输入
     * @param {string} value
     * @return {void}
     */
    const inputMobile = (value: string) => {
      onlyNumber('mobile', value)
      if (/^[1][0-9]{10}$/.test(value)) {
        data.isSendMsgDisabled = false
      } else {
        data.isSendMsgDisabled = true
        data.isLoginBtnDisabled = true
      }
    }

    /**
     * @description: 监听验证码输入
     * @param {string} value
     * @return {void}
     */
    const inputCode = (value: string) => {
      onlyNumber('code', value)
      if (
        /^[0-9]{6}$/.test(value) &&
        /^[1][0-9]{10}$/.test(data.formData.mobile)
      ) {
        data.isLoginBtnDisabled = false
      } else {
        data.isLoginBtnDisabled = true
      }
    }

    /**
     * @description: change 普通用户表单
     * @param {*}
     * @return {void}
     */
    const changeUser = () => {
      if (data.formData.username !== '' && data.formData.password !== '') {
        data.isLoginBtnDisabled = false
      } else {
        data.isLoginBtnDisabled = true
      }
    }

    /**
     * @description: 清空input
     * @param {*}
     * @return {void}
     */
    const clearInput = () => {
      data.isSendMsgDisabled = true
      data.isLoginBtnDisabled = true
    }
    onMounted(() => {
      isDevRouter || resetRouter()
    })
    return {
      ...toRefs(data),
      onSubmit,
      handleSendMsg,
      toggleLoginType,
      inputMobile,
      inputCode,
      clearInput,
      changeUser,
      handleValidateCode,
      BlurUserName
    }
  }
})
