
import { defineComponent, computed } from 'vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  name: 'Home',
  setup() {
    const router = useRouter()
    const routeName = computed(
      () =>
        router.currentRoute.value.meta && router.currentRoute.value.meta.title
    )
    return {
      routeName
    }
  }
})
